import React from 'react';

const ThumbsUp = ({ customClassNames, width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={`c-ThumbsUp ${customClassNames}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86924 2.08198C10.1617 1.42403 10.8141 1 11.5342 1C13.4483 1 15 2.55171 15 4.46584V8H18.5032C20.9547 8 22.8294 10.1852 22.4567 12.6082L21.3797 19.6082C21.0795 21.5596 19.4005 23 17.4262 23H4C2.34315 23 1 21.6569 1 20V13C1 11.3431 2.34315 10 4 10H6.35013L9.86924 2.08198ZM8 21H17.4262C18.4134 21 19.2529 20.2798 19.403 19.3041L20.4799 12.3041C20.6663 11.0926 19.7289 10 18.5032 10H15C13.8954 10 13 9.10457 13 8V4.46584C13 3.69457 12.4043 3.06242 11.6479 3.00435L8.17775 10.8123C8.1277 10.9249 8.06805 11.0313 8 11.1306V21ZM6 12V21H4C3.44772 21 3 20.5523 3 20V13C3 12.4477 3.44772 12 4 12H6Z"
        fill={fill}
      />
    </svg>
  );
};

ThumbsUp.defaultProps = {
  width: '24',
  height: '24',
  fill: '#1B1B18',
  customClassNames: ''
};

export default ThumbsUp;
